@use 'sass:meta';

@layer theme, base, legacy, components, utilities;
@layer legacy {
  /*
    On n'importe plus "bootstrap", on préférera importer le fichier "app_bootstrap.css" qui provient de la commande
    `sass ./src/app/legacy/scss/app_bootstrap/bootstrap.scss ./src/app/legacy/scss/app_bootstrap.css`
  */
  @include meta.load-css("app_bootstrap");

  @include meta.load-css("vendors");
  @include meta.load-css("app");
  @include meta.load-css("modules/biens");
  @include meta.load-css("modules/contacts");
  @include meta.load-css("modules/demandeurs");
  @include meta.load-css("modules/eskimmo");
  @include meta.load-css("modules/estimations");
  @include meta.load-css("modules/locations");
  @include meta.load-css("modules/ventes");
}
